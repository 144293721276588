import service from '../js/http-services.js'
import router from '@/router'

const instrumentHistory = {
    namespaced: true,

    state: {
        instrumentsHistory: [],
        cedata: [],
        pedata: [],
        peceCloseChart: {
            tooltip: {
                trigger: 'axis',
                axisPointer: { type: 'cross', animation: true },
                borderWidth: '0',
                textStyle: {
                    color: '#ffffff'
                }
            },
            legend: { data: ["PUT PRICE", "CALL PRICE"], bottom: "1%" },
            grid: { left: "1%", right: "1%", bottom: 60, containLabel: true },
            toolbox: {
                feature: {
                    // dataView: { show: true, readOnly: false },
                    magicType: { show: true, title: '', type: ["line", "bar", 'pie'] },
                    restore: { show: false },
                    saveAsImage: { show: true, title: 'Save' },
                },
            },
            xAxis: {
                type: "category",
                boundaryGap: false,
                axisPointer: {
                    type: "shadow", shadowColor: "#E6E5E4",
                },
                data: [],
            },
            yAxis: { type: "value" },
            series: [
                {
                    name: "PUT PRICE",
                    type: "line",
                    smooth: true,
                    data: [],
                    symbolSize: 4,
                },
                {
                    name: "CALL PRICE",
                    type: "line",
                    smooth: true,
                    data: [],
                    symbolSize: 4,
                },
            ],
        },
    },

    mutations: {
        setFiiDiiData(state, payload) {
            state.instrumentsHistory = payload
        }
    },

    actions: {
        async getInstrumentHistory({ state, commit }, payload) {
            let cejson = {
                // "instrument_identifier": `OPTIDX_${payload.instrument}_${payload.expiry.replace('21', '2021')}_CE_${payload.strikeprice}`
                'indexName': payload.instrument,
                'expiryDate': payload.expiry.replace('21', '2021'),
                'strikePrice': payload.strikeprice,
            }
            await service.getInstrumentHistory(cejson).then((response) => {
                state.cedata = response.data.result[0].data
                state.pedata = response.data.result[1].data
                // state.peceCloseChart.xAxis.data = [];
                // // this.combinedChart.xAxis.data = [];
                // // this.peceoiChart.xAxis.data = [];
                // this.peceCloseChart.xAxis.data = [];
                // // this.combinedChart.series[0].data = [];
                // // this.peceoiChart.series[0].data = [];
                // // this.peceoiChart.series[1].data = [];
                // // this.peceCloseChart.series[0].data = [];
                // this.peceCloseChart.series[1].data = [];
                // // this.combinedChart.series[1].data = [];
                // // this.combinedChart.series[2].data = [];
                // var pedata = instrumentsHistory[0].data;
                // var cedata = instrumentsHistory[1].data;
                // var lastTradeTime = [];
                // var pedata2 = [];
                // var cedata2 = [];
                // var combined = [];
                // pedata.forEach((elementPe) => {
                //     cedata.forEach((elementCe) => {
                //         if (elementPe.LASTTRADETIME == elementCe.LASTTRADETIME) {
                //             lastTradeTime.push(elementCe);
                //             pedata2.push(elementPe);
                //             cedata2.push(elementCe);
                //             combined.push((elementPe.CLOSE + elementCe.CLOSE).toFixed(2));
                //             //
                //             this.peceoiChart.series[0].data.push(elementPe.OPENINTEREST);
                //             this.peceoiChart.series[1].data.push(elementCe.OPENINTEREST);
                //         }
                //     });
                // });
                // lastTradeTime.forEach((element) => {
                //     this.combinedChart.xAxis.data.push(this.unixToTime(parseFloat(element.LASTTRADETIME)));
                //     this.peceoiChart.xAxis.data.push(this.unixToTime(parseFloat(element.LASTTRADETIME)));
                //     this.peceCloseChart.xAxis.data.push(this.unixToTime(parseFloat(element.LASTTRADETIME)));
                // });
                // pedata2.forEach((element) => {
                //     this.combinedChart.series[2].data.push(element.CLOSE);
                //     this.peceCloseChart.series[0].data.push(element.CLOSE);
                // });
                // cedata2.forEach((element) => {
                //     this.combinedChart.series[1].data.push(element.CLOSE);
                //     this.peceCloseChart.series[1].data.push(element.CLOSE);
                // });
                // combined.forEach((element) => {
                //     this.combinedChart.series[0].data.push(element);
                // });
            })
        }
    },
}

export default instrumentHistory