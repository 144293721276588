<template>
  <div id="app" >
    <v-app>
      <v-alert v-if="errorType" type="success"></v-alert>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  data: () => ({
    drawer: false,
    group: null,
    errorType: ''
  }),
  watch: {
    group() {
      this.drawer = false;
    },
  },
  components: {
   ...mapState(['errorType','loading']),
  },
  mounted(){
    const theme = localStorage.getItem("dark_theme");
      if (theme) {
          if (theme == "true") {
              this.$vuetify.theme.dark = true;
          } else {
              this.$vuetify.theme.dark = false;
          }
      }
  }
};
</script>
