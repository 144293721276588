import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'
import '../assets/css/overwrite.css'
import '../assets/css/style.css'
import '../assets/css/themeskin.css'

Vue.use(Vuetify)
const vuetify = new Vuetify({
  theme: {
    options: {
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
      },
    },
    themes: {
      light: {
        primary: "#2EA6F5",
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        lightgreen: colors.green.lighten1,
        grey: "#f5f5f5",
        lightgrey: '#929292',
        thcolor: '#545454'
      },
      dark: {
        primary: colors.blue.lighten3,
        ightgreen: colors.green.lighten1,
        grey: "#f5f5f5",
        lightgrey: '#929292',
        thcolor: '#545454'
      }
    }
  }
})
export default vuetify
