import service from '../js/http-services.js'
const dialog = {
    namespaced: true,

    state: {
        showDialog: false,
        selectedOrders: [],
    },

    mutations: {
        setDialog(state, data) {
            state.showDialog = data;
        },
        setSelectedOrders(state, data) {
            console.log(data);
            state.selectedOrders = []
            state.selectedOrders = data;
        }
    },

    actions: {
        async cancelOrder({ state, dispatch},payload) {
            console.log(payload);
            let json = {
                "exch": payload.Exchange,
                "nestOrderNumber": payload.Nstordno,
                "trading_symbol": payload.Trsym
            }
            state.showDialog = false;
            console.log(json);
            service.cancelOrder(json).then((response) => {
                dispatch('orders/fetchOrderBook',null,{ root: true });
            })
        }
    },
}

export default dialog