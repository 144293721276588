import service from '../js/http-services.js'
import router from '@/router'

const fiiDiiData = {
    namespaced: true,

    state: {
       fiiDiiList: [],
       Derivativelist: [],
       groupedDiiFiiData:[],
       CAPITALMARKET: {
            title: {
                text: '', subtext: '', left: 'center'
            },
            tooltip: {
                trigger: 'axis', axisPointer: { animation: false }
            },
            toolbox: {
                feature: {
                    magicType: { show: true, title: '', type: ["line", "bar", 'pie'] },
                    restore: { show: true, title: 'Restore' },
                    saveAsImage: { title: 'Save' }
                }
            },
            axisPointer: {
                link: { xAxisIndex: 'all' },
            },
        //    legend: { data: ["FII NET", "DII NET"], top:'2%'},
           grid: [{ left: 52, right: 15,height: '35%'}, { left: 52, right: 15, top: '55%', height: '35%'}],
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
                    data: []
                },
                {
                    gridIndex: 1,
                    type: 'category',
                    boundaryGap: true,
                    axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
                    data: [],
                    position: 'bottom'
                }
            ],
            yAxis: [ { name: 'Crores', type: 'value',}, { gridIndex: 1, name: 'Crores',type: 'value', }
            ],
            series: [{name: 'FII NET', type: 'bar', data: [] },
            {name: 'DII NET',type: 'bar', xAxisIndex: 1,yAxisIndex: 1, data: []} ],
            visualMap: {
                inRange: {
                    color: ['#d63031', '#00b894']
                },
                text: ['>0', '<0'],
                calculable: true,
                show: false
            },

       },
       DERIVATIVES: {
            title: { text: '',subtext: '',left: 'center'},
            tooltip: {trigger: 'axis',axisPointer: {}},
            // toolbox: {
            //     feature: {
            //         magicType: { show: true, title: '', type: ["line", "bar", 'pie'] },
            //         restore: { show: true, title: 'Restore' },
            //         saveAsImage: { title: 'Save' }
            //     }
            // },
        //    legend: { data: ["FUT IDXNET", "OPT IDXNET", "FUT STKNET", 'OPT STKNET'], top: '1%' },
           axisPointer: {link: { xAxisIndex: 'all', type: "shadow" }},
           grid: [{ left: 52, right: 15, top: '5%', height: '17%' }, { left: 52, right: 15, top: '28%', height: '17%' },
            { left: 52, right: 15, top: '53%', height: '17%' }, { left: 52, right: 15, top: '78%', height: '17%' }],
            xAxis: [{
                    type: 'category',
                    boundaryGap: true,
                    axisLine: { onZero: true },
                    axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
                    data: []},
                {
                    gridIndex: 1,
                    type: 'category',
                    boundaryGap: true,
                    axisLine: { onZero: true },
                    axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
                    data: [],
                    position: 'bottom'
                },
                {
                    gridIndex: 2,
                    type: 'category',
                    boundaryGap: true,
                    axisLine: { onZero: true },
                    axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
                    data: [],
                    position: 'bottom'
                },
                {
                    gridIndex: 3,
                    type: 'category',
                    boundaryGap: true,
                    axisLine: { onZero: true },
                    axisPointer: { type: "shadow", shadowColor: "#E6E5E4" },
                    data: [],
                    position: 'bottom'
                },
            ],
            yAxis: [
                { name: '', type: 'value'},
                { gridIndex: 1,name: '',type: 'value'},
                { gridIndex: 2, name: '',type: 'value'},
                { gridIndex: 3,name: '',type: 'value'},
            ],
            series: [
                { name: 'FUT IDXNET',type: 'bar',data: []},
                { name: 'OPT IDXNET',type: 'bar',xAxisIndex: 1,yAxisIndex: 1,data: []},
                { name: 'FUT STKNET', type: 'bar', xAxisIndex: 2, yAxisIndex: 2, data: []},
                { name: 'OPT STKNET', type: 'bar', xAxisIndex: 3, yAxisIndex: 3, data: [] },],
           visualMap: {
               inRange: {
                   color: ['#d63031', '#00b894']
               },
               text: ['>0', '<0'],
               calculable: true,
               show:false
           },
       },
    },

    mutations: {
        setFiiDiiData(state, payload) {
            state.fiiDiiList = []
            var dii = [];
            var fii = [];
            var allData = payload.map(({ BuyValue, Catagory, Date, NetValue, SellValue }) => (
                Catagory == 'FII/FPI' ? { fiBuyValue: BuyValue, Catagory: Catagory, fiDate: Date, fiNetValue: NetValue, fiSellValue: SellValue } : 
                    { diBuyValue: BuyValue, Catagory: Catagory, diDate: Date, diNetValue: NetValue, diSellValue: SellValue }
                ));
            state.groupedDiiFiiData = allData;
            allData.forEach(x => {
                if (x.Catagory == 'DII') {
                    dii.push(x)
                }
                if (x.Catagory == 'FII/FPI') {
                    fii.push(x)
                }
            })
            dii.forEach(x => {
                fii.forEach(y => {
                    if (x.diDate == y.fiDate) {
                        state.fiiDiiList.push({...x,...y})
                    }
                })
            })
        },

        setDerivativeData(state, data){
          state.Derivativelist = data
        },

        setCapitalMarket(state, payload) {
            state.CAPITALMARKET.xAxis[0].data = []
            state.CAPITALMARKET.xAxis[1].data = []
            state.CAPITALMARKET.series[0].data = []
            state.CAPITALMARKET.series[1].data = []
            payload.forEach(el => {
                state.CAPITALMARKET.xAxis[0].data.push(el.diDate)
                state.CAPITALMARKET.xAxis[1].data.push(el.fiDate)
                state.CAPITALMARKET.series[0].data.push(el.fiNetValue)
                state.CAPITALMARKET.series[1].data.push(el.diNetValue)
            })
        },

        setDerivativeChartData(state, payload) {
            state.DERIVATIVES.xAxis[0].data = []
            state.DERIVATIVES.xAxis[1].data = []
            state.DERIVATIVES.xAxis[2].data = []
            state.DERIVATIVES.xAxis[3].data = []
            state.DERIVATIVES.series[0].data = []
            state.DERIVATIVES.series[1].data = []
            state.DERIVATIVES.series[2].data = []
            state.DERIVATIVES.series[3].data = []
            payload.forEach(el => {
                state.DERIVATIVES.xAxis[0].data.push(el.Date)
                state.DERIVATIVES.xAxis[1].data.push(el.Date)
                state.DERIVATIVES.xAxis[2].data.push(el.Date)
                state.DERIVATIVES.xAxis[3].data.push(el.Date)
                state.DERIVATIVES.series[0].data.push(el.FutureIdxNetValue)
                state.DERIVATIVES.series[1].data.push(el.OptionIdxNetValue)
                state.DERIVATIVES.series[2].data.push(el.FutureStkNetValue)
                state.DERIVATIVES.series[3].data.push(el.OptionStkNetValue)
            })
        }
    },

    actions: {
        async getFiiDiiData({ state, commit, rootState }) {
            rootState.loading = true;
            await service.getFiiDiiData().then((response) => {
                if (response.status == 200 && response.data.emsg != 'No Data') {
                    state.fiiDiiList = [];
                    commit('setFiiDiiData', response.data.result);
                    commit('setCapitalMarket', state.fiiDiiList);
                }
            },
                (err) => {
                    // if (err.response.status === 401) { }
                }).catch(error => {
                    console.log(error);
                }).finally(() => { rootState.loading = false; });
        },

        async getDerivativeList({state, commit, rootState}) {
            rootState.loading = true;
            await service.getDerivativeData().then((response) => {
                if(response.status == 200 && response.data.emsg != 'No Data'){
                  if(response.data['status'] == 1){
                      commit('setDerivativeData', response.data['result'])
                      commit('setDerivativeChartData', response.data['result'])
                  }
                }
            }, (err) => {
                // if (err.response.status === 401) { }
            }).catch(error => {
            }).finally(() => { rootState.loading = false; })
        }
    },
    getters: {
        getderivative: (state) => state.Derivativelist
    }
}

export default fiiDiiData