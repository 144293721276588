import service from '../js/http-services.js'
import router from '@/router'

const positions = {
    namespaced: true,

    state: {
        positionBook: [],
        funds: [],
        positionGroupedData: [],
        positionTotalPnl:0,
    },
 
    mutations: {
        setFunds(state, funds) {
            state.funds = funds
        },

        setPositions(state, positionBook) {
            state.positionBook = positionBook
        },

        setHistoryData(state, payload) {
            state.historyData = payload;
        },

        setGroupedPositions(state, payload) {
            state.positionGroupedData = []
            state.positionBook = []
            state.positionTotalPnl = 0
             payload.forEach(el => {
                 if(el.Exchange == "NFO") {
                     state.positionBook.push(el)
                 }
            })
            state.positionGroupedData = state.positionBook.reduce(function (r, a) {
                (r[a.Symbol] = r[a.Symbol] || []).push(a);
                state.positionTotalPnl += parseFloat((a.MtoM).replace(",", ''))
                return r;
            }, {});
            console.log((state.positionGroupedData))
        }
    },

    actions: {

        //Method to call positionbook Details
        //@28-09-2021

        fetchPositionBook: function ({ commit,rootState }) {
            let json = {
                ret: "NET"
            }
            rootState.loading = true;
            service.getPositionBook(json).then((response) => {
                if (response.status == 200 && response.data.emsg != 'No Data') {
                    commit('setPositions', response.data)
                    commit('setGroupedPositions', response.data)
                }
            },(err) => {
                if (err.response.status === 401) {
                    router.push("/").catch(() => { });
                    alert('Unauthorized')
                }
            }).catch(error => {
                console.log(error);
            })
        },

        // Method to call funds Details
        // @29-09-2021
       
        getFundsData: function ({ commit , rootState}) {
            service.getFundDetails().then((response) => {
                if (response.status == 200) {
                    commit('setFunds', response.data[0])
                }
            }, (err) => {
                if (err.response.status === 401) {
                    router.push("/").catch(() => {});
                    alert('Unauthorized')
                }
            }).catch(error => {
                console.log(error)
            })
            .finally(() => { rootState.loading = false; });
        }
    },

    getters: {
        getFunds: (state) => state.funds
    }
}

export default positions
