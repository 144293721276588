import service from '../js/http-services.js'
import router from '@/router'

const userDetails = {
    namespaced: true,

    state: {
        userData: [],
        profileData: [],
        zebuVendorApiKey: "C81SRCXN55557LKTQ78NVFJ9O35D950TO1PU676BA0X7V82JFCO3D61J3CYZKORSC91FTVU4SRG81QA1TKJG18P63YZ1CMFYU5V3",
        bnrVendorApiKey: "IH7PYUYGYJ6LW06PHCPMU7IC9XANZVU3H0VBF9Z1M07PJV9HY4XB46N14CXPMUH75XWIV2GJP396TINTOWMW8KBE0DIHVLTPCB6D",
        zebuVendorName: "amoga",
        bnrVendorName: "AMOGA",
        authCode: "",
        userId: "",
        sessionId: ""
    },

    mutations: {
        setUserData(state, payload) {
            state.userData = payload;
        },

        setProfileData(state, payload) {
            state.profileData = payload;
        },
    },

    actions: {
        getUserDetails: async function ({ state, commit, dispatch }) {
            var currentBroker = localStorage.getItem('currentBroker')
            var authCode = localStorage.getItem('authCode');
            let json = {
                "vendorApiKey": currentBroker == 'BNR' ? state.bnrVendorApiKey : currentBroker == 'ZEBU' ? state.zebuVendorApiKey : '',
                "vendor": currentBroker == 'BNR' ? state.bnrVendorName : currentBroker == 'ZEBU' ? state.zebuVendorName : '',
                "authCode": authCode
            }
            service.getSessionId(json).then((response) => {
                if (response.status == 200 && response.data.stat != 'Not_Ok') {
                    localStorage.setItem('userId', response.data.clientId)
                    localStorage.setItem('sessionId', response.data.userSession)
                    //getUserDetails
                    var url = window.location
                    dispatch('customerDetails')
                    window.location.href = `${url.origin}/#/dashboard`
                }
                commit('setUserData', response.data);
            })
                .catch(error => {
                    console.log(error);
                })
        },

        customerDetails: async function ({ commit, rootState }) {
            rootState.loading = true; 
            var sessionId = localStorage.getItem('sessionId')
            if(sessionId) {
            service.getCustomerDetails().then((response) => {
                if (response.status == 200) {
                    commit('setProfileData', response.data);
                }
            }, (err) => {
                if (err.response.status === 401) {
                    router.push("/").catch(() => { });
                    alert('Unauthorized')
                }
            })
            .finally(() => { rootState.loading = false; });
        }
        },
    },

    getters: {
        getUserData(state) { return state.userData },
    }
}

export default userDetails