
export default {
    data() {return {}},

    methods: {
        unixToTime(data) {
            let unix_timestamp = data;
            var date = new Date(unix_timestamp);
            var hours = date.getHours();
            var minutes = "0" + date.getMinutes();
            var formattedTime = hours + ":" + minutes.substr(-2);
            return formattedTime;
        },
        getCurrentPrice(value) {
            if (value)
                return this.strickPriceList.length != 0 && this.strickPriceList ? this.strickPriceList.reduce((prev, curr) => Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev) : '';
        },
        comaSeperation(x){
            x=x.toString();
            var afterPoint = '';
            if(x.indexOf('.') > 0)
               afterPoint = x.substring(x.indexOf('.'),x.length);
            x = Math.floor(x);
            x=x.toString();
            var lastThree = x.substring(x.length-3);
            var otherNumbers = x.substring(0,x.length-3);
            if(otherNumbers != '')
                lastThree = ',' + lastThree;
            var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
            return (res);
        }
    },

};
