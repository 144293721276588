import service from '../js/http-services.js'

const symbols = {
    namespaced: true,

    state: {
        symbolsList: [],
        expiryList: [],
        result: [],
        strickPriceList: [],
        ceStrikePriceList: [],
        peStrikePriceList: [],
        optionType: [],
        currentStrikePrice: '',
        instrument: '',
        expiryDate: '',
        strikePrice: '',
        currentExpiryDate: null,
    },

    mutations: {
        setSymbolsList(state, payload) {
            state.symbolsList = payload;
        },

        setResult(state, payload) {
            state.result = payload;
        },

        setExpiryList(state, payload) {
            console.log(state, payload)
            var expList = (payload == "BANKNIFTY") ? JSON.parse(localStorage.getItem('bankExpiryList')) : JSON.parse(localStorage.getItem('niftyExpiryList'))
            console.log(expList)
            state.expiryList = expList.ExpiryList;
            state.expiryList.sort((a, b) => new Date(a) - new Date(b));
            console.log(state.expiryList)
            state.currentExpiryDate = expList.ExpiryList[0]
            console.log(state.currentExpiryDate,"currentExp")
        },

        setStrickPrice(state, payload) {
            if (payload) {
               var expList = payload.instrument == "BANKNIFTY" ? JSON.parse(localStorage.getItem('bankExpiryList')) : JSON.parse(localStorage.getItem('niftyExpiryList'))
                state.strickPriceList = [];
                if (payload.expiry != '18NOV21') {
                expList.ExpiryDetailList[payload.expiry].forEach((el) => {
                    if (el.strikePrice != '' && el.strikePrice != null && el.strikePrice != undefined) {
                        state.strickPriceList.push(el.strikePrice)
                    }
                })
                }
            }
        },
        setCurrentStrikePrice(state, payload) {
            state.currentStrikePrice = payload
            //console.log("sate", state.currentStrikePrice)
        }
    },

    actions: {
        async getSymbolsList({ state, commit }) {
            var symbols = JSON.parse(localStorage.getItem("symbolsList"))
            if (symbols == null || symbols == undefined) {
                await service.getSymbolsList().then((response) => {
                    state.symbolsList = [];
                    if (response.status == 200) {
                        commit('setSymbolsList', response.data.result);
                        localStorage.setItem("symbolsList", JSON.stringify(response.data.result))
                    }
                },
                    (err) => {
                        if (err.response.status === 401) {
                            this.$router.push("/").catch(() => { });
                        }
                    }).catch(error => {
                        console.log(error);
                    })
            } else {
                commit('setSymbolsList', symbols)
            }
        },

        async getSymbolsDetails({ state, commit }, payload) {
            var bankExpList = JSON.parse(localStorage.getItem('bankExpiryList'))
            var niftyExpList = JSON.parse(localStorage.getItem('niftyExpiryList'))
            if (payload && bankExpList == null || bankExpList == undefined && payload == "BANKNIFTY") {
                let json = {
                    "symbol": 'BANKNIFTY'
                };
                await service.getSymbolsDetails(json).then((response) => {
                    if (response.status == 200 ) {
                        localStorage.setItem("bankExpiryList", JSON.stringify(response.data.result))
                        // commit('setResult', response.data.result)
                        commit('setExpiryList', 'BANKNIFTY');
                        state.expiryList = response.data.result.ExpiryList;
                    }
                },
                    (err) => {
                        if (err.response.status === 401) {
                            // this.$router.push("/").catch(() => { });
                        }
                    }).catch(error => {
                        console.log(error);
                    })
            } else {
                state.expiryList = JSON.parse(localStorage.getItem('bankExpiryList')).ExpiryList;
                commit('setExpiryList', 'BANKNIFTY');
            }
            if (payload && niftyExpList == null || niftyExpList == undefined && payload == "NIFTY") {
                let json = {
                    "symbol": 'NIFTY'
                };
                await service.getSymbolsDetails(json).then((response) => {
                    if (response.status == 200) {
                        commit('setResult', response.data.result)
                        state.expiryList = response.data.result.ExpiryList;
                        localStorage.setItem("niftyExpiryList", JSON.stringify(response.data.result))
                        commit('setExpiryList', 'NIFTY');
                    }
                },
                    (err) => {
                        if (err.response.status === 401) {
                            // this.$router.push("/").catch(() => { });
                        }
                    }).catch(error => {
                        console.log(error);
                    })
            } else {
                state.expiryList = JSON.parse(localStorage.getItem('niftyExpiryList')).ExpiryList;
                commit('setExpiryList', 'NIFTY');
            }
        },

        async getCurrentMarketPrice({ state, commit, rootState }, payload) {
            let json = {
                "symbol": payload
            }
            await service.getCurrentMarketPrice(json).then((response) => {
                if (response.status == 200 && response.data.message == 'Success') {
                    //commit('setCurrentStrikePrice', response.data.result)
                    // console.log('currentStrikePrice'+ response.data.result)
                    // console.log(rootState)
                    rootState.currentStrikePrice = response.data.result;
                    rootState.updateCurrentStrikePrice = response.data.result;
                }
                else{
                    rootState.updateCurrentStrikePrice = rootState.currentStrikePrice = 41200;
                }

            })
        },
    },
}

export default symbols