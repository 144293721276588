import service from '../js/http-services.js'
import router from '@/router'

const orders = {
    namespaced: true,

    state: {
        orderBookData: [],
        currentOrders: [],
        openOrders: [],
    },

    mutations: {
        orderBook(state, data) {
            state.orderBookData = data;
            state.orderBookData.forEach(el => {
                el.showdropdown = false;
                el.showUllist = false
            })
            console.log(state.orderBookData)
        },

        openOrders(state, data) {
            state.currentOrders = data
        },

        executedOrders(state) {
            state.currentOrders = state.orderBookData.filter((element) => {
                return element.Status == "cancelled" || element.Status == "rejected" ||
                    element.Status == "complete" || element.Status == "cancelled after market order"
            });
        }
    },

    actions: {
        async fetchOrderBook({ state, commit, rootState } ,currentTab) {
            rootState.loading = true;
            await service.getOrderBook().then((response) => {
                state.executedOrders = [];
                if (response.status == 200 && response.data.emsg != 'No Data') {
                    var nfoData = [];
                    response.data.forEach(element => {
                         if(element.Exchange == 'NFO') {
                             nfoData.push(element)
                         }
                    })
                    commit('orderBook', nfoData);
                    if (state.orderBookData != null) {
                        state.openOrders = state.orderBookData.filter((element) => {
                            return element.Status == "open" || element.Status == "trigger pending" ||
                                element.Status == "after market order req received" || element.Status == "modify after market order req received"
                        });
                    }
                    commit('openOrders', state.openOrders)
                }
            },
                (err) => {
                    if (err.response.status === 401) {
                        router.push("/").catch(() => { });
                        alert('Unauthorized')
                    }
                }).catch(error => {
                    console.log(error);   
                }).finally(() => { rootState.loading = false; });
        },

        getOpenOrders({ commit ,state }) {
            commit('openOrders', state.openOrders);
        },

        getExecutedOrders({ commit },payload) {
            commit('executedOrders', payload);
        }
    },

    getters: {
        orderBookData(state) { return state.orderBookData }
    }
}

export default orders