import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routers = [
  {
    path: '/', redirect: '/', name: 'home', component: () => import('../views/home.vue'),
    children: [
      { path: '/', name: 'homecontent', component: () => import('../views/homecontent.vue') },
      { path: '/plans', name: 'plans', component: () => import('../views/plans.vue') },
    ],
  },
  {
    path: '/dashboard', redirect: 'dashboard', component: () => import('../views/gridlayout.vue'), meta: { title: 'Dashboard' },
    children: [
      // { path: '/', redirect: 'dashboard' },
      { path: '/dashboard', name: 'dashboard', component: () => import('../views/dashboard.vue'), meta: { title: 'Dashboard' } },
      { path: '/positions', component: () => import('../views/positions.vue'), meta: { title: 'Positions' } },
      { path: '/positions1', component: () => import('../views/positionspage.vue'), meta: { title: 'Positions' } },
      { path: '/orders', component: () => import('../views/orders'), meta: { title: 'Orders' } },
      { path: '/calendar', component: () => import('../views/calendar'), meta: { title: 'Calenders' } },
      { path: '/fiidii', component: () => import('../views/fiidii.vue'), meta: { title: 'FII & DII' } },
      { path: '/payoffdiagram', component: () => import('../views/payoffdiagram.vue'), meta: { title: 'Payoff Diagram' } },
      { path: '/option/optionchain/:id', component: () => import('../views/options/optionchain.vue'), meta: { title: 'Option Chain' } },
      // { path: '/option/oia/:id', component: () => import('../views/oichart.vue'), meta: { title: 'OI chart' } },
      { path: '/option/oianalysis/:id', component: () => import('../views/options/oianalysis.vue'), meta: { title: 'OI Analysis' } },
      { path: '/option/oistatistics/:id', component: () => import('../views/options/oistatistics.vue'), meta: { title: 'OI Statistics' } },
      { path: '/option/straddlecharts/:id', component: () => import('../views/options/straddlecharts.vue'), meta: { title: 'Straddle Charts' } },
      { path: '/option/premiumleft/:id', component: () => import('../views/options/premiumleft.vue'), meta: { title: 'Premium Left' } },
      { path: '/option/totaloidifference/:id', component: () => import('../views/options/oidifference.vue'), meta: { title: 'OI Difference' } },
      { path: '/option/cumilativeoi/:id', component: () => import('../views/options/cumilativeoi.vue'), meta: { title: 'Cumilative OI' } },
      { path: '/option/multistrikeoi/:id', component: () => import('../views/options/multistrikeoi.vue'), meta: { title: 'Multi Strike OI' } },
      { path: '/option/pricevsoi/:id', component: () => import('../views/options/pricevsoi.vue'), meta: { title: 'Price vs OI' } },
      { path: '/option/ivchart/:id', component: () => import('../views/options/ivchart.vue'), meta: { title: 'IV chart' } },
      { path: '/planspage', component: () => import('../views/plans.vue'), meta: { title: 'Plans' } },
      { path: '/settings', component: () => import('../views/settings.vue'), meta: { title: 'Settings' } },
      { path: '/news', component: () => import('../views/news.vue'), meta: { title: 'News' } },
      { path: '/futures', component: () => import('../views/futures.vue'), meta: { title: 'Future Data' } },
      { path: '/vuexdata', component: () => import('../views/vuexdata.vue'), meta: { title: '' } },
      { path: '/socket', component: () => import('../views/socket.vue'), meta: { title: '' } },
      // { text: "News", svg: "news", dropicon: "false", view: "news", items: [] },
      // {
      //   text: "Settings",
      //   svg: "settings",
      //   dropicon: "false",
      //   view: "settings",
      //   items: [],
      // },
      //{path: '/option',component: () => import( '../views/optionchain.vue'),meta: {title: 'Option'},
      //children: [
      //{path: '/',redirect: 'optionchain',component: () => import('../views/optionchain.vue'),meta: {title: 'Option Chain'}},
      //{path: '/optionchain',component: () => import( '../views/optionchain.vue'),meta: {title: 'Option Chain'}}
      // ]
      // },
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: routers,
})
// router.beforeEach((to, from, next) => {
//   /*
//   *WOULD LIKE TO ACCESS THE URL PARAMS HERE*
//   **to.query** is not working in hash mode.
//   */
//  console.log(to)
//   console.log(from)
//   console.log(next)
// })

export default router
