import service from '../js/http-services.js'

const calendars = {
    namespaced:true,

    state: {
        holidayCalendar: []
    },

    mutations: {
        SET_HOLIDAYLIST(state, data){
            state.holidayCalendar = data
        }
    },

    actions: {
        holidayData({ commit, rootState }) {
            rootState.loading = true;
            service.getHolidayDetails().then((response) => {
                if (response.status = 200) {
                    if(response.data['status'] == 1){
                    commit('SET_HOLIDAYLIST', response.data['result'])
                }
           
            }
            }).catch(error => {
                console.log(error)
            }).finally(() => { rootState.loading = false; });
        }
    },

    getters: {
        getcalendardata: (state) => state.holidayCalendar
    }
}

export default calendars