import service from '../js/http-services.js'
const orderWindow = {
    namespaced: true,
    state: {
        showOrderWindow: false,
        orderType: 'Buy',
        toggleOrder: false,
        currentOrder: [],
        priceRangeDetails: [],
        scriptQuoteDetails: [],
    },
    mutations: {
        setOrderWindow(state, payload, dispatch) {
            state.showOrderWindow = payload
            console.log(state.showOrderWindow)
            if (state.showOrderWindow) {
                this.dispatch('orderWindow/getNewPriceRange')
            }
        },
        setOrderType(state, payload) {
            state.orderType = payload
        },
        setToggleOrder(state, payload) {
            state.toggleOrder = payload
        },
        setCurrentOrder(state, payload) {
            state.currentOrder = payload
            console.log(state.currentOrder, "state.currentOrder")
        },
        setPriceRangeDetails(state, payload) {
            state.priceRangeDetails = payload
            console.log(state.priceRangeDetails)
        },
        setScriptQuoteDetails(state, payload) {
            state.scriptQuoteDetails = payload
        }
    },
    actions: {
        async placeOrder({ state, context, rootState }, payload) {
            console.log(this.state.userDetails.userData)
            // let json = {
            //     "complexty": "amo",
            //     "discqty": "0",
            //     "exch": state.currentOrder.Exchange.toString(),
            //     "pCode": payload.pcode.toString(),
            //     "prctyp": payload.pcode.toString(),
            //     "price": payload.priceType.toString(),
            //     "qty": payload.qty.toString(),
            //     "ret": "DAY",
            //     "symbol_id": state.currentOrder.token.toString(),
            //     "trading_symbol": state.currentOrder.Trsym.toString(),
            //     "transtype": state.currentOrder.Trantype.toUpperCase() == "B" ? 'BUY' : 'SELL',
            //     "trigPrice": payload.trgPrice,
            // };
            // service.placeOrder(json).then(response => {
            //     console.log(response)
            // })
            // console.log(json, '------------------')
        },
        async getNewPriceRange({ state, commit, dispatch, rootState }) {
            rootState.loading = true;
            let json = {
                "exch": state.currentOrder.Exchange.toString(),
                "symbol": state.currentOrder.token.toString(),
            }
            await service.getNewPriceRange(json).then(response => {
                console.log(response)
                if (response.status == 200) {
                    commit('setPriceRangeDetails', response.data)
                    dispatch('getScriptQuoteDetails')
                }
            }, (err) => {
                if (err.response.status === 401) {
                    router.push("/").catch(() => { });
                    alert('Unauthorized')
                }
            })
                .finally(() => { rootState.loading = false; });
        },
        async getScriptQuoteDetails({ state, commit, rootState }) {
            rootState.loading = true;
            let json = {
                "exch": state.currentOrder.Exchange.toString(),
                "symbol": state.currentOrder.token.toString(),
            }
            await service.getScriptQuoteDetails(json).then(response => {
                console.log(response)
                if (response.status == 200) {
                    commit('setScriptQuoteDetails', response.data)
                }
            }, (err) => {
                if (err.response.status === 401) {
                    router.push("/").catch(() => { });
                    alert('Unauthorized')
                }
            })
                .finally(() => { rootState.loading = false; });
        }
    }
}

export default orderWindow