import axios from "axios";
import { mapState } from "vuex"

var currentBroker = localStorage.getItem("currentBroker")
if (currentBroker == "ZEBU") {
  var BASEURL = 'https://api.zebull.in/rest/V2MobullService/'
} else if (currentBroker == "BNR") {
  var BASEURL = 'https://uat.bnrsecurities.com/rest/BNRUATService'
}
const AXIOS = axios.create({
  baseURL: `${BASEURL}`,
});

export default {
  data: () => ({
    userId: this.$store.userId
  }),

  computed: {
    ...mapState('userDetails', ['userId', 'sessionId']),
  },

  getAuthHeaders() {
    let userId = localStorage.getItem("userId");
    let sessionId = localStorage.getItem("sessionId");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userId + " " + sessionId,
    };
    return headers;
  },

  getSessionId: async function (jsonObj) {
    return await AXIOS.post(`sso/getUserDetails`, jsonObj, {
      headers: this.headers,
    });
  },

  getOrderBook: async function () {
    return await AXIOS.get(`api/placeOrder/fetchOrderBook`, {
      headers: this.getAuthHeaders(),
    });
  },

  getPositionBook(jsonObj) {
    return AXIOS.post(`api/positionAndHoldings/positionBook`, jsonObj, {
      headers: this.getAuthHeaders(),
    });
  },

  getFundDetails() {
    return AXIOS.get('api/limits/getRmsLimits', {
      headers: this.getAuthHeaders()
    })
  },

  //cancelOrders
  cancelOrder: function (jsonObj) {
    return AXIOS.post('api/placeOrder/cancelOrder', jsonObj, {
      headers: this.getAuthHeaders()
    })
  }, 

  getHolidayDetails() {
    return AXIOS.post('http://rest.amoga.tech/AmogaWebService/v2/amoga/getHolidayList', {
      headers: this.headers
    })
  },

  getCustomerDetails: async function () {
    return await AXIOS.get(`api/customer/accountDetails`, {
      headers: this.getAuthHeaders(),
    });
  },

  //placeOrder
  placeOrder(jsonObj) {
    return AXIOS.post(`api/placeOrder/executePlaceOrder`, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  },
  //get price for orderWindow
  getNewPriceRange(jsonObj) {
    return AXIOS.post(`api/ScripDetails/getPriceRange`, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  },
  //get script Quote Details for orderWindow
  getScriptQuoteDetails(jsonObj) {
    return AXIOS.post(`api/ScripDetails/getScripQuoteDetails`, jsonObj, {
      headers: this.getAuthHeaders(),
    })
  },

  //getSymbolsList
  //@author senthil
  //method POST
  getSymbolsList() {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/amoga/getSymbol`, {
      headers: this.headers
    });
  },

  getSymbolsDetails(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/amoga/getSymbolDetails`, jsonObj, {
      headers: this.headers
    });
  },

  getCurrentMarketPrice(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/scrip/getCurrentMarketPrice`, jsonObj, {
      headers: this.headers
    });
  },

  getOptionChain(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/OHLC/getOptionChainData`, jsonObj, {
      headers: this.headers
    });
  },

  getFiiDiiData(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/OHLC/getFIIAndDIIData`, jsonObj, {
      headers: this.headers
    });
  },
  getInstrumentHistory(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/OHLC/getGDFLChartData`, jsonObj, {
      headers: this.headers
    });
  },

  getDerivativeData(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/OHLC/getDerivativeFIIData`, jsonObj, {
      headers: this.headers
    })
  },

  getPcrData(jsonObj) {
    return AXIOS.post(`http://rest.amoga.tech/AmogaWebService/v2/OHLC/getOiAndVolumePCR`, jsonObj, {
      headers: this.headers
    })
  }
}